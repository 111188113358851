import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import Pack from "./pages/Pack";
import Gallery from "./pages/Gallery";
import Search from "./pages/Search";
import "./App.css";

function App() {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pack/:nick" element={<Pack />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/search" element={<Search />} />
    </Routes>
  );
}

export default App;
