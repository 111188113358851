import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { getByQuery, getPagesByQuery } from "../../api/MOCK";
import { useContext, useEffect, useState } from "react";
import Card from "../../components/Card";
// import Loading from "../../components/Loading";
import { formatNumericInput } from "../../helpers/formatNumericInput";
import { Icon } from "@iconify/react";
import { ThemeContext } from "../../context/ThemeContext";
import "./styles/Search.css";

function Search() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  // Get search term and page from URL parameters
  const searchTerm = queryParams.get("q") || "";
  const pageParam = queryParams.get("page");
  const page = pageParam ? parseInt(pageParam, 10) : 1; // Default to page 1 if not specified

  const [items, setItems] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(1); // Total number of pages
  const [inputPage, setInputPage] = useState(page); // Input for page number
  const [numberInputPage, setNumberInputPage] = useState(page); // Number value from input

  const { theme: t, autoTheme } = useContext(ThemeContext);
  const theme = t === "device" ? autoTheme : t;

  const scroll = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedItems = await getByQuery(page, searchTerm);
        const totalPages = await getPagesByQuery(searchTerm); // Get total pages based on query

        if (fetchedItems.error) {
          throw new Error("Network Error");
        }

        setItems(fetchedItems);
        setPages(totalPages);
        setInputPage(page);
      } catch (error) {
        // Handle error (e.g., show an error message)
      }
    };

    scroll();
    fetchData();
  }, [page, searchTerm]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(true);
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, []);

  // const handleLoad = () => {
  //   setLoading(true);
  // };

  // const classLoad = loading ? "complete-load" : "in-load";

  const disabledPrev = page === 1;
  const disabledNext = page === pages;

  const handlePrevPage = (e) => {
    e.preventDefault();
    if (!disabledPrev) {
      const newPage = page - 1;
      navigate(`/search/?q=${searchTerm}&page=${newPage}`);
    }
  };

  const handleNextPage = (e) => {
    e.preventDefault();
    if (!disabledNext) {
      const newPage = page + 1;
      navigate(`/search/?q=${searchTerm}&page=${newPage}`);
    }
  };

  const handleChangeInputPage = ({ target }) => {
    const { value } = target;
    const newValue = formatNumericInput(value);
    setInputPage(newValue);
    setNumberInputPage(Number(newValue)); // Update the number value
  };

  const handleInputPage = (e) => {
    e.preventDefault();
    if (numberInputPage > 0 && numberInputPage <= pages) {
      navigate(`/search/?q=${searchTerm}&page=${numberInputPage}`);
    }
  };

  return (
    <main className="search">
      <Header />
      {items.length > 0 && (
        <div className={`search__title c-${theme}`}>
          <h2>
            Exibindo resultados para{" "}
            <span className={`bg-${theme}-2`}>{searchTerm}</span>
          </h2>
        </div>
      )}
      <section className={`home__cards`}>
        {items.map((data, i) => (
          <Card
            data={data}
            key={i}
            i={i}
            setup={() => {}} // Provide a function if needed
            // handleLoad={handleLoad}
          />
        ))}
      </section>
      {items.length === 0 && (
        <p className="home__cards__empty-query">
          Nenhum conteúdo correspondente
        </p>
      )}
      {/* <Loading className={classLoad} /> */}
      {items.length > 0 && (
        <div className={`home__pages`}>
          <button
            className="home__pages__page-btn-arrow"
            onClick={handlePrevPage}
            disabled={disabledPrev}
          >
            <Icon icon="ic:round-play-arrow" rotate={2} />
          </button>
          <form onSubmit={handleInputPage}>
            <input
              type="text"
              onChange={handleChangeInputPage}
              value={inputPage}
              className={`bg-${theme}-2 c-${theme}`} // Ensure `theme` is defined or replace with a default value
            />
          </form>
          <div></div>
          <span>{pages}</span>
          <button
            className="home__pages__page-btn-arrow"
            onClick={handleNextPage}
            disabled={disabledNext}
          >
            <Icon icon="ic:round-play-arrow" />
          </button>
        </div>
      )}
      <Footer />
    </main>
  );
}

export default Search;
