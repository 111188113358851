import React, { useState } from "react";
import "./styles/LazyImage.css";

const LazyImage = ({
  src,
  alt,
  className,
  onClick,
  REF,
  handleLoad = () => {},
}) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
    handleLoad();
  };

  return (
    <div
      className={`${className} lazy-image-wrapper ${
        loaded ? "loaded" : "loading"
      } `}
      ref={REF}
    >
      <img
        src={src}
        alt={alt}
        loading="lazy"
        className={`${className}`}
        onLoad={handleImageLoad}
        onClick={onClick}
      />
    </div>
  );
};

export default LazyImage;
