import lS from "manager-local-storage";
import data from "../data/data.json";

const pageSize = 10; // Defina o tamanho da página

const getFavorites = () => {
  // const CLEAN_FAV = "---megapacks.fun---";

  // const isClean = lS.get(CLEAN_FAV) ?? false;

  // if (!isClean) {
  //   lS.remove("mega-packs-favorites");
  //   lS.set(CLEAN_FAV, true);
  // }

  const favoriteNicks = lS.get("mega-packs-favorites") ?? [];
  const favorites = [];
  favoriteNicks.forEach((f) => {
    const e = data.find((d) => d.nick === f);
    if (e) favorites.push(e);
  });
  return favorites;
};

export const get = (page, type) => {
  const totalRecords = data.length;

  // Calcule o offset para a paginação inversa
  const offset = totalRecords - page * pageSize;

  // Ajuste o offset para garantir que ele nunca seja negativo
  const adjustedOffset = Math.max(0, offset);

  // Determine o limite para a última página
  const limit =
    page * pageSize > totalRecords ? totalRecords % pageSize : pageSize;

  const newArray = [...data];

  let sort = newArray;

  if (type === "popular") {
    const sortedData = newArray.sort((a, b) => {
      const aHasPopular = "popular" in a;
      const bHasPopular = "popular" in b;

      if (aHasPopular && !bHasPopular) {
        return 1;
      }

      if (!aHasPopular && bHasPopular) {
        return -1;
      }

      if (aHasPopular && bHasPopular) {
        return b.popular - a.popular;
      }

      return 0;
    });

    sort = sortedData;
  } else if (type === "favorites") {
    return getFavorites();
  } else if (type === "az") {
    const sortedData = newArray.sort((a, b) =>
      b.name.toLowerCase().localeCompare(a.name.toLowerCase())
    );
    sort = sortedData;
  } else if (type === "za") {
    const sortedData = newArray.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    sort = sortedData;
  }

  // Obtenha os registros com limite e offset
  const paginatedItems = sort.slice(adjustedOffset, adjustedOffset + limit);

  return paginatedItems.reverse(); // Apenas inverter o resultado na função get
};

export const getPages = () => {
  const totalRecords = data.length;
  const totalPages = Math.ceil(totalRecords / pageSize);
  return totalPages;
};

// Função de paginação para query
export const getByQuery = (page, query) => {
  const formattedQuery = query.toLowerCase();

  // Filtra os itens que contêm a query no nick ou name
  const filteredItems = data.filter(
    (item) =>
      item.nick.toLowerCase().includes(formattedQuery) ||
      item.name.toLowerCase().includes(formattedQuery)
  );

  // Ordena os itens pelo índice de aparição do termo, priorizando os que começam com o termo
  filteredItems.sort((a, b) => {
    const nickIndexA = a.nick.toLowerCase().indexOf(formattedQuery);
    const nameIndexA = a.name.toLowerCase().indexOf(formattedQuery);
    const nickIndexB = b.nick.toLowerCase().indexOf(formattedQuery);
    const nameIndexB = b.name.toLowerCase().indexOf(formattedQuery);

    // Pega o menor índice entre nick e name para cada item
    const minIndexA = Math.min(
      nickIndexA === -1 ? Infinity : nickIndexA,
      nameIndexA === -1 ? Infinity : nameIndexA
    );
    const minIndexB = Math.min(
      nickIndexB === -1 ? Infinity : nickIndexB,
      nameIndexB === -1 ? Infinity : nameIndexB
    );

    // Ordena com base no menor índice
    return minIndexA - minIndexB;
  });

  // Ajuste o número da página para começar do zero
  const pageIndex = page > 0 ? page - 1 : 0;

  // Calcule o offset e limite para a paginação
  const offset = pageIndex * pageSize;
  const limit = pageSize;

  // Obtenha os itens filtrados com limite e offset
  const paginatedItems = filteredItems.slice(offset, offset + limit);

  return paginatedItems;
};

// Função para obter o número total de páginas com base na query
export const getPagesByQuery = (query) => {
  const formattedQuery = query.toLowerCase();

  // Filtra os itens que contêm a query no nick ou name
  const filteredItems = data.filter(
    (item) =>
      item.nick.toLowerCase().includes(formattedQuery) ||
      item.name.toLowerCase().includes(formattedQuery)
  );

  // Calcule o número total de páginas
  const totalRecords = filteredItems.length;
  const totalPages = Math.ceil(totalRecords / pageSize);

  return totalPages;
};

export default data;
