import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import "./styles/Banner.css";

function Banner() {
  const { theme: t, autoTheme } = useContext(ThemeContext);
  const theme = t === "device" ? autoTheme : t;

  return (
    <section className={`banner c-${theme}`}>
      <div>
        <h1>300+</h1>
        <span>modelos</span>
      </div>
      <ul>
        <li>Onlyfans</li>
        <li>Privacy</li>
        <li>Fansly</li>
        <li>Patreon</li>
      </ul>
    </section>
  );
}

export default Banner;