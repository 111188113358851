// QueryContext.js
import lS from "manager-local-storage";
import React, { createContext, useEffect, useState } from "react";

export const PremiumContext = createContext();

export const PremiumProvider = ({ children }) => {
  const [premium, setPremium] = useState(false);

  useEffect(() => {
    const getIsPremium = () => {
      const premium = lS.get("premium");
      if (premium) setPremium(true);
    };
    getIsPremium();
  }, []);

  return (
    <PremiumContext.Provider value={{ premium, setPremium }}>
      {children}
    </PremiumContext.Provider>
  );
};
