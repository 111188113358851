import LazyImage from "../LazyImage";
import Slider from "react-slick";
import "./styles/CardImage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Icon } from "@iconify/react/dist/iconify.js";

function CardImage({ images, onDbClick, showTempElement = false, favorite, handleLoad, slidesToShow = 1 }) {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    draggable: true,
  };

  return (
    <>
      {showTempElement && (
        <div className="temp-element">
          {favorite && <Icon icon="material-symbols:heart-check-rounded" />}
          {!favorite && <Icon icon="material-symbols:heart-broken-outline" />}
        </div>
      )}
      <section className="card-image" onDoubleClick={onDbClick}>
        <Slider {...settings}>
          {images.map((src, i) => (
            <div key={i} className="card-image__image-wrapper">
              <LazyImage
                src={src}
                alt={`Image ${i}`}
                className="card-image__image"
                handleLoad={handleLoad}
              />
            </div>
          ))}
        </Slider>
      </section>
    </>
  );
}

export default CardImage;
