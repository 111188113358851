import { Icon } from "@iconify/react/dist/iconify.js";
import "./styles/Snowflakes.css";

function Snowflakes() {
  return (
    <div className="snowflakes">
      {Array.from({ length: 84 }).map((_, index) => {
        const size = Math.random() * 30 + 10; // Tamanho entre 10px e 40px
        return (
          <Icon
            key={index}
            icon="map:snow"
            width={size}
            height={size}
            className="snowflake-icon"
          />
        );
      })}
    </div>
  );
}

export default Snowflakes;
