import "../CardImage/styles/CardImage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Icon } from "@iconify/react/dist/iconify.js";

function CardImage2({
  images,
  onDbClick,
  showTempElement = false,
  favorite,
  handleLoad,
  slidesToShow = 1,
}) {

  return (
    <>
      {showTempElement && (
        <div className="temp-element">
          {favorite && <Icon icon="material-symbols:heart-check-rounded" />}
          {!favorite && <Icon icon="material-symbols:heart-broken-outline" />}
        </div>
      )}
      <section className="card-image" onDoubleClick={onDbClick}>
        <div className="image__container">
          <div
            className="image__container__blur"
            style={{ backgroundImage: `url(${images[1]})` }}
          ></div>
          <img src={images[0]} alt="" />
          {/* <img src={images[2]} alt="" />
          <img src={images[3]} alt="" /> */}
        </div>
      </section>
    </>
  );
}

export default CardImage2;
