import lS from "manager-local-storage";
import "./styles/dbClickNotify.css";
import { useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ThemeContext } from "../../context/ThemeContext";

function DbClickNotify() {
  const [show, setShow] = useState(true);

  const dbClickKey = "mega-packs-dbclick";

  const { theme: t, autoTheme } = useContext(ThemeContext);

  const theme = t === "device" ? autoTheme : t;

  useEffect(() => {
    // Esconder o #circle após 2 segundos
    const haveView = () => {
      const viewDb = lS.get(dbClickKey) ?? false;
      if (viewDb) {
        setShow(false);
      }
    };
    haveView();
  }, []);

  const handleCloseNotify = () => {
    lS.set(dbClickKey, true);
    setShow(false);
  };

  return (
    show && (
      <section className={`db-click-notify bg-${theme}`}>
        <p className={`db-click-notify__text c-${theme}`}>
          <Icon icon="material-symbols:heart-check-rounded" /> Toque duas vezes
          no card para adicioná-lo aos seus favoritos.
        </p>
        <button
          className="db-click-notify__close-btn"
          onClick={handleCloseNotify}
        >
          <Icon icon="material-symbols:close-rounded" />
        </button>
      </section>
    )
  );
}

export default DbClickNotify;
