// QueryContext.js
import React, { createContext, useEffect, useState } from "react";
import lS from "manager-local-storage";

const lSKey = "mega-packs-theme";

const preferDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

const autoTheme = preferDark ? "dark" : "light";
let initialState = lS.get(lSKey) ?? "device";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(initialState);

  const handleSetTheme = (newTheme) => {
    setTheme(newTheme);
    lS.set(lSKey, newTheme);
  };

  useEffect(() => {
    let bodyClass;
    bodyClass = theme === "light" ? "bg-light" : "bg-dark";
    if (theme === "device") {
      bodyClass = `bg-${autoTheme}`;
    }
    document.body.classList.add(bodyClass);

    // Remover a classe do tema anterior ao mudar
    return () => {
      document.body.classList.remove("bg-light");
      document.body.classList.remove("bg-dark");
    };
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, autoTheme, handleSetTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
